import Button                        from '@widesk/components/Button';
import Navigation                    from '@widesk/components/Navigation';
import { NavigationItem }            from '@widesk/components/Navigation';
import Popover                       from '@widesk/components/Popover';
import { useState }                  from 'react';

export type SettingsNavigationProps = {
	item: Omit<Required<NavigationItem>, "link">;
}

export default function SettingsNavigation(props: SettingsNavigationProps) {
	const [showTooltip, setShowTooltip] = useState(true);
	const [open, setOpen] = useState(false);
	
	return (
		<Popover
			trigger="click"
			open={open}
			content={
				<Navigation 
					style={{ background: "#000c17", width: 234 }}
					items={props.item.children.map(c => ({...c, style: { paddingLeft: '24px', paddingRight: '16px' }}))} 
				/>
			}
			placement="rightBottom"
			color="#000c17"
			onOpenChange={() => setShowTooltip(!showTooltip)}
		>
			<Button
				title={props.item.label}
				tooltipProps={{ placement: "left", zIndex:showTooltip ? undefined : -9 }}
				type="primary"
				style={{ width: 50 }}
				icon={props.item.icon}
				onClick={() => setOpen(!open)}
				onBlur={() => setTimeout(() => setOpen(false), 200)}
			>
				{null}
			</Button>
		</Popover>
	);
}
