import dayjs           from 'dayjs';
import devToolbarStore from '@widesk/stores/devToolbarStore';

const KEY_PREFIX = 'FILTERS_';
// TODO: Mettre en place le format de date L-LT dans l'url

class ListFilterManagerStore {
	public set(key: string, params: Record<string, unknown>, storeIntoUrl?: boolean) {
		if (storeIntoUrl) {
			const newUrl = transformParamsToUrl(params);
			if ((location.pathname + location.search) !== newUrl) {
				window.history.replaceState(null, '', newUrl);

				devToolbarStore.clearRequests();
			}
		}

		localStorage.setItem(KEY_PREFIX + key, JSON.stringify(params));
	}

	public get(key: string, storedIntoUrl?: boolean) {
		if (storedIntoUrl) {
			const urlParams = transformUrlToParams(window.location.href);
			if (urlParams) {
				// S'il y a des paramètres dans l'URL, ils sont utilisés avant le localStorage
				return urlParams;
			}
		}

		// On retourne les paramètres du localStorage
		return JSON.parse(localStorage.getItem(KEY_PREFIX + key) || '{}') as Record<string, unknown>;
	}

	// Supprime toutes les clés du localStorage
	public clear() {
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key?.startsWith(KEY_PREFIX)) {
				localStorage.removeItem(key);
			}
		}
	}
}

export const transformParamsToUrl = <K extends string>(params: Record<K, unknown>, withLocation:boolean = true) => {
	const urlParams: string[] = [];

	const editValue = (value: unknown) => {
		if (typeof value === 'string' || value instanceof dayjs) {
			const date = isValidFilterDate(value as string);

			if (date) {
				return date.toISOString();
			}
		}

		return value;
	};

	Object.entries(params).map(([key, value]) => {
		if (typeof value === 'undefined') {
			return;
		}

		let newValue: unknown = value;

		if (Array.isArray(value)) {
			newValue = `[${value.map(v => editValue(v)).join(',')}]`;
		} else {
			newValue = editValue(value);
		}

		urlParams.push(`${key}=${newValue}`);
	});

	if (!withLocation) {
		return urlParams.length ? `?${urlParams.join('&')}` : '';
	}

	return urlParams.length ? `${location.pathname}?${urlParams.join('&')}` : location.pathname;
};

const transformUrlToParams = (url: string) => {
	const str = url.split(/[?#]/)[1];

	if (str) {
		const items = str.split('&');

		const editValue = (value: string) => {
			const date = isValidFilterDate(value as string);

			if (date) return date;
			if (value === 'true') return true;
			if (value === 'false') return false;

			return value;
		};

		return items.reduce<Record<string, unknown>>((acc, item) => {
			const [key, value] = item.split('=');

			if (key && value) {
				const isArray = value.startsWith('[') && value.endsWith(']');

				if (isArray) {
					acc[key] = value.slice(1, -1).split(',').map(v => editValue(v));
				} else {
					acc[key] = editValue(value);
				}
			}

			return acc;
		}, {});
	}

	return null;
};

const isValidFilterDate = (str: string | Dayjs) => {
	const date = dayjs(str, undefined, true);

	if (date.isValid()) {
		// Si la valeur est un string, il y a un check supplémentaire pour la validité de l'objet date
		if (typeof str === 'string' && str !== date.toISOString()) {
			return false;
		}

		return date as Dayjs;
	}

	return false;
};

export default new ListFilterManagerStore();
