import { ControlOutlined }         from '@ant-design/icons';
import { HomeOutlined }            from '@ant-design/icons';
import { SettingOutlined }         from '@ant-design/icons';
import { ShopOutlined }            from '@ant-design/icons';
import { ShoppingCartOutlined }    from '@ant-design/icons';
import { QrcodeOutlined }          from '@ant-design/icons';
import { ContactsOutlined }        from '@ant-design/icons';
import { InboxOutlined }           from '@ant-design/icons';
import { BarChartOutlined }        from '@ant-design/icons';
import ClientGroupModel            from '@models/shop/ClientGroupModel';
import ClientModel                 from '@models/shop/ClientModel';
import OrderModel                  from '@models/shop/OrderModel';
import PeriodGroupModel            from '@models/shop/PeriodGroupModel';
import ProductTemplateModel        from '@models/shop/ProductTemplateModel';
import ProductModel                from '@models/shop/ProductModel';
import TaxonomyModel               from '@models/shop/TaxonomyModel';
import EntertainmentModel          from '@/models/services/fondations/EntertainmentModel';
import PartnershipModel            from './models/services/shop/PartnershipModel';
import PromoCodeModel              from '@models/shop/PromoCodeModel';
import VatModel                    from '@models/shop/VatModel';
import ExpirationTypeModel         from '@models/shop/ExpirationTypeModel';
import MailModel                   from '@models/mail/MailModel';
import MessageTemplateModel        from '@models/shop/MessageTemplateModel';
import ScheduleGroupModel          from '@models/shop/ScheduleGroupModel';
import DeliverableModel            from '@models/shop/DeliverableModel';
import CartModel                   from '@models/shop/CartModel';
import RelatedContentModel         from '@models/shop/RelatedContentModel';
import DeliverableTransferModel    from '@models/shop/DeliverableTransferModel';
import ProductSaleModel            from '@models/shop/ProductSaleModel';
import CancelReasonModel           from '@models/shop/CancelReasonModel';
import TagModel                    from '@models/shop/TagModel';
import ShipmentModel               from '@models/shop/ShipmentModel';

export default function nav(id: id) {
	return [
		{ label: 'Accueil', icon: <HomeOutlined />, link: `/entertainments/${id}/home` },
		{
			key: 'sales',
			label: 'Ventes',
			icon: <ShoppingCartOutlined />,
			children: [
				{ label: OrderModel.localize('plural'), link: OrderModel.pagePath('splitView') },
				{ label: CartModel.localize('plural'), link: CartModel.pagePath('splitView') },
			],
		},
		{
			key: 'orders',
			label: 'Suivi des ventes',
			icon: <BarChartOutlined />,
			children: [
				{ label: `Suivi - ${ProductSaleModel.localize('plural')}`, link: ProductSaleModel.pagePath('list') },
			],
		},
		{
			key: 'shop',
			label: 'Catalogue',
			icon: <ShopOutlined />,
			children: [
				{ label: ProductModel.localize('plural'), link: ProductModel.pagePath('splitView') },
				{ label: PeriodGroupModel.localize('plural'), link: PeriodGroupModel.pagePath('splitView') },
			],
		},
		{
			key: 'codes',
			label: 'Billetterie',
			icon: <QrcodeOutlined />,
			children: [
				{ label: 'Tickets et badges', link: DeliverableModel.pagePath('splitView') },
				{ label: DeliverableTransferModel.localize('plural'), link: DeliverableTransferModel.pagePath('splitView') },
			],
		},
		{
			key: 'badges',
			label: 'Badges',
			icon: <ContactsOutlined />,
			children: [
				{ label: 'Distribution des badges', link: `/entertainments/${id}/deliverables/badge_delivery` },
				{ label: 'Liste des badges', link: `/entertainments/${id}/deliverables/badges` },
			],
		},
		{
			key: 'delivery',
			label: 'Livraisons',
			icon: <InboxOutlined />,
			children: [
				{ label: `Interface d'expédition`, link: `/entertainments/${id}/deliverables/shipping` },
			],
		},
	];
}

export const navigationSettings = {
	label: 'Paramètres',
	icon: <SettingOutlined />,
	children: [
		{ label: 'Campagne de vente', link: EntertainmentModel.pagePath('splitView') },
		{ label: MessageTemplateModel.localize('plural'), link: MessageTemplateModel.pagePath('splitView') },
		{ label: ScheduleGroupModel.localize('plural'), link: ScheduleGroupModel.pagePath('splitView') },
		{ label: TaxonomyModel.localize('plural'), link: TaxonomyModel.pagePath('splitView') },
		{ label: ProductTemplateModel.localize('plural'), link: ProductTemplateModel.pagePath('splitView') },
		{ label: PartnershipModel.localize('plural'), link: PartnershipModel.pagePath('splitView') },
		{ label: RelatedContentModel.localize('plural'), link: RelatedContentModel.pagePath('splitView') },
		{ label: PromoCodeModel.localize('plural'), link: PromoCodeModel.pagePath('splitView') },
		{ label: ClientModel.localize('plural'), link: ClientModel.pagePath('splitView') },
		{ label: ClientGroupModel.localize('plural'), link: ClientGroupModel.pagePath('splitView') },
		{ label: 'Impressions des badges', link: 'deliveries/print/split' },
		{ label: ShipmentModel.localize('plural'), link: ShipmentModel.pagePath('splitView') },

	],
};

export const navigationAdmin = {
	label: 'Administration',
	icon: <ControlOutlined />,
	children: [
		{ label: MailModel.localize('plural'), link: `mails/split` },
		{ label: CancelReasonModel.localize('plural'), link: CancelReasonModel.pagePath('list') },
		{ label: ExpirationTypeModel.localize('plural'), link: ExpirationTypeModel.pagePath('list') },
		{ label: VatModel.localize(), link: VatModel.pagePath('list') },
		{ label: TagModel.localize('plural'), link: TagModel.pagePath('splitView') },
	],
};
