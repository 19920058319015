import { LinkProps, Link as ReactRouterLink }                           from 'react-router-dom';

const Link = (props: LinkProps) => {
	return (
		<ReactRouterLink
			target={props.target}
			{...props}
		/>
	);
};

export default Link;
