import AntdSpin  from 'antd/lib/spin';
import View      from '@widesk/components/View';
import _omit     from 'lodash/omit';
import React     from 'react';
import { Props } from '@widesk/components/View/viewTypes';

export type SpinProps = Omit<Props, 'size'> & {
	spinning?: boolean;
	size?: 'small' | 'large';
	tip?: React.ReactNode;
};

export default function Spin(props: React.PropsWithChildren<SpinProps>) {
	const spinning = typeof props.spinning === 'undefined' ? true : props.spinning;

	return (
		<View centerV {..._omit(props, ['size', 'children'])}>
			<AntdSpin
				size={props.size}
				spinning={spinning}
				children={props.children}
			/>

			{!!props.tip && <View center marginT={20}>{props.tip}...</View>}
		</View>
	);
}
