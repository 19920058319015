import PeriodModel               from '@models/shop/PeriodModel';
import ProductModel              from '@models/shop/ProductModel';
import ProductPricingStatusModel from '@models/shop/ProductPricingStatusModel';
import doc                       from '@widesk/decorators/doc';
import model                     from '@widesk/decorators/model';
import ShopServiceModel          from '@/models/ShopServiceModel';
import TimestampAble             from '@widesk/models/traits/TimestampAble';
import Blamable                  from '@/models/traits/Blamable';

@model.urnResource('product_pricing')
@doc.path('/product_pricings/{?id}')
export default class ProductPricingModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'period': id;
		'period.periodGroup': id;
		'product': id;
		'product.entertainment': id;
		'product.entertainment.entertainmentUrn': Urn;
		'product.productTemplate': id;
		'productPricingStatus': id;
		'productPricingStatus.reference': Shop_ProductPricingStatusReference;
		'stock[between]': string;
		'stock[gt]': string;
		'stock[gte]': string;
		'stock[lt]': string;
		'stock[lte]': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'stock': string;
		'updatedAt': string;
		'period.startDate': string;
	};

	@doc.model(PeriodModel) declare period: PeriodModel;
	@doc.model(ProductModel) declare product: ProductModel;
	@doc.model(ProductPricingStatusModel) declare productPricingStatus: ProductPricingStatusModel;
	@doc.number declare stock: number;
}
