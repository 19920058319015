import ShopEntertainmentModel         from "@/models/services/shop/EntertainmentModel";
import appStore                       from "@/stores/appStore";
import Spin                           from "@widesk/components/Spin";
import { observer }                   from "mobx-react";
import React                          from "react";
import { useEffect }                  from "react";

export type ContextValue = {
	entertainment: ShopEntertainmentModel | 'all' | null;
	entertainmentUrn?: Urn;
};

export const EntertainmentContext = React.createContext<ContextValue>({} as ContextValue);

export type EntertainmentProviderProps = React.PropsWithChildren;

const EntertainmentProvider = observer(({ children }: EntertainmentProviderProps) => {
	const { entertainment, entertainmentUrn } = appStore;

	useEffect(() => {
		if (window.location.pathname === '/' && entertainment !== 'all') {
			appStore.changeEntertainment('all');
		}
	}, [entertainment]);

	if (!(entertainmentUrn || entertainment === 'all')) {
		return <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}><Spin /></div>;
	}

	return (
		<EntertainmentContext.Provider
			value={{
				entertainment,
				entertainmentUrn,
			}}
		>
			{children}
		</EntertainmentContext.Provider>
	);
});

export default EntertainmentProvider;
