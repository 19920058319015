import ShopServiceModel from '@/models/ShopServiceModel';
import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import TimestampAble    from '@widesk/models/traits/TimestampAble';

@model.cacheDuration()
@model.urnResource('mime_type')
@doc.path('/stonecode_file_mime_types/{?id}')
export default class MimeTypeModel extends TimestampAble(ShopServiceModel) {
	private declare _tsFilters: {
		'reference': FileMimeTypeReference;
		'typeMimeTypes.type': id
		'typeMimeTypes.type.reference': FileTypeReference,
	};

	private declare _tsSorts: {
		'id': string;
		'reference': string;
		'updatedAt': string;
	};

	@doc.string declare reference: FileMimeTypeReference;
}
