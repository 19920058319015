import TimestampAble    from '@widesk/models/traits/TimestampAble';
import ShopServiceModel from '@/models/ShopServiceModel';
import model            from '@widesk/decorators/model';
import doc              from '@widesk/decorators/doc';

@model.urnResource('service')
@doc.path('/stonecode_file_services/{?id}')
export default class ServiceModel extends TimestampAble(ShopServiceModel) {
	private declare _tsFilters: {
		'serviceUrn': string;
	};

	private declare _tsSorts: {
		'id': string;
		'updatedAt': string;
	};

	@doc.modelUrn(ServiceModel) declare service: ServiceModel;
}
