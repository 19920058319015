import ProductModel          from '@models/shop/ProductModel';
import doc                   from '@widesk/decorators/doc';
import model                 from '@widesk/decorators/model';
import ShopServiceModel      from '@/models/ShopServiceModel';
import TimestampAble         from '@widesk/models/traits/TimestampAble';
import WithEntertainmentPath from '@/models/mixins/withEntetainmentPath';

@model.urnResource('product_sale')
@doc.path('/product_sales/{?id}')
export default class ProductSaleModel extends TimestampAble(WithEntertainmentPath(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'product': id;
		'sold[between]': string;
		'sold[gt]': string;
		'sold[gte]': string;
		'sold[lt]': string;
		'sold[lte]': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'sold': string;
		'updatedAt': string;
	};

	@doc.model(ProductModel) declare product: ProductModel;
	@doc.number declare sold: number;
}
