export default [
	{
		handle: { crumb: () => 'Liste des badges' },
		path: '/entertainments/:entertainmentId/deliverables/badges',
		lazy: async () => {
			const Module = await import('@pages/shop/DeliverableBadgeSplitView/DeliverableBadgeSplitViewPage');
			return { Component: Module.default };
		},
	},
];
