import Blamable         from '@/models/traits/Blamable';
import PolicyModel      from '@models/file/PolicyModel';
import ServiceModel     from '@models/file/ServiceModel';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';
import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';

@model.cacheDuration()
@model.urnResource('type')
@doc.path('/stonecode_file_types/{?id}')
export default class TypeModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'deprecated': '0' | '1';
		'policy': id;
		'policy.reference': FilePolicyReference;
		'reference': FileTypeReference;
		'service': id;
		'service.serviceUrn': string;
		'system': '0' | '1';
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'updatedAt': string;
	};

	@doc.boolean declare defaultLock: boolean;
	@doc.boolean declare deprecated: boolean;
	@doc.string declare label: string;
	@doc.number declare maxPerSource: number;
	@doc.number declare maxSize: number;
	@doc.model(PolicyModel) declare policy: PolicyModel;
	@doc.string declare reference: FileTypeReference;
	@doc.model(ServiceModel) declare service: ServiceModel;
	@doc.boolean declare system: boolean;
	@doc.string declare urnMask: string;
}
