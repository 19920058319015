import ExpirationTypeModel        from '@models/shop/ExpirationTypeModel';
import ProductTemplateStatusModel from '@models/shop/ProductTemplateStatusModel';
import ProductTypeModel           from '@models/shop/ProductTypeModel';
import VatModel                   from '@models/shop/VatModel';
import doc                        from '@widesk/decorators/doc';
import model                      from '@widesk/decorators/model';
import ShopServiceModel           from '@/models/ShopServiceModel';
import TimestampAble              from '@widesk/models/traits/TimestampAble';
import Blamable                   from '@/models/traits/Blamable';
import Translatable               from '@widesk/models/traits/Translatable';

@model.urnResource('product_template')
@doc.path('/product_templates/{?id}')
export default class ProductTemplateModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'description': true,
	'termsOfUse': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'productType': id;
		'productTemplateStatus': id;
		'productTemplateStatus.reference': Shop_ProductTemplateStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
		'vat': id;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'createdAt': string;
		'updatedAt': string;
	};

	@doc.number declare bankDelay: number;
	@doc.model(ExpirationTypeModel) declare expirationType: ExpirationTypeModel;
	@doc.string declare label: string;
	@doc.boolean declare manualValidation: boolean;
	@doc.number declare maxQuantityPerOrder: number;
	@doc.model(ProductTemplateStatusModel) declare productTemplateStatus: ProductTemplateStatusModel;
	@doc.model(ProductTypeModel) declare productType: ProductTypeModel;
	@doc.model(VatModel) declare vat: VatModel;
}
