import AntdInput                         from 'antd/lib/input';
import React                             from 'react';
import { HTMLInputTypeAttribute }        from 'react';
import { InputRef }                      from 'antd';

export type WideskInputRef = {
	focus: () => void;
	getPastedValue: (value: string) => string | undefined
	selectAll: () => void;
};

export type InputProps = FieldProps & {
	value?: string;
	onChange?: (value: string) => void;
	onFocus?: (e: any) => void;
	onBlur?: (e: any) => void;
	suffix?: React.ReactNode;
	addonBefore?: React.ReactNode;
	addonAfter?: React.ReactNode;
	type?: HTMLInputTypeAttribute ;
	allowClear?: boolean;
	initialValue?: string;
}

export const Input = React.forwardRef<WideskInputRef, InputProps>((props: InputProps, ref) => {
	const inputRef = React.useRef<InputRef>(null);

	React.useImperativeHandle(ref, () => {
		const ref = inputRef.current;

		return {
			// Retourne la nouvelle valeur de l'input avec la pastedValue incluse au niveau du curseur.
			getPastedValue: (pastedValue) => {
				if (ref && ref.input) {
					const { selectionStart, selectionEnd, value } = ref.input;

					ref.input.focus();

					return `${value.slice(0, selectionStart || 0)}${pastedValue}${value.slice(selectionEnd || 0)}`;
				}
			},
			// Méthode pour focus l'input antd
			focus: () => {
				if (ref && ref.input) {
					setTimeout(() => ref.input?.focus(), 250);
				}
			},
			// Méthode pour sélectionner tout le texte de l'input
			selectAll: () => {
				if (ref && ref.input) {
					ref.input.select();
				}
			},
		};
	});

	React.useEffect(() => {
		if (props.autoFocus && inputRef.current) {
			setTimeout(inputRef.current.focus, 250);
		}
	}, []);

	return (
		<AntdInput
			ref={inputRef}
			addonBefore={props.addonBefore}
			addonAfter={props.addonAfter}
			placeholder={props.placeholder}
			autoFocus={props.autoFocus}
			disabled={props.disabled}
			onChange={props.onChange}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			size={props.size}
			value={props.value}
			defaultValue={props.initialValue}
			style={props.style}
			id={props.id ? `input-${props.id}` : undefined}
			suffix={props.suffix}
			type={props.type}
			allowClear={props.allowClear}
		/>
	);
});

export default Input;
