import appStore                 from '@/stores/appStore';
import { ModelClass }           from '@mathquis/modelx/lib/types/collection';
import ApiModel                 from '@widesk/models/ApiModel';
import ShopEntertainmentModel   from '../services/shop/EntertainmentModel';
import ApiCollection            from '@widesk/models/ApiCollection';

export default function WithEntertainmentPath<T extends ModelClass<ApiModel>>(Base: T) {
	const c = class WithEntertainmentPath extends Base {

		private static _entertainmentPrefix() {
			return `/entertainments/${appStore.entertainment instanceof ShopEntertainmentModel ? appStore.entertainment.id : 'all'}`;
		}

		public get pagePath(): string {
			return `${WithEntertainmentPath._entertainmentPrefix()}${this.path}`;
		}

		public static pagePath(type?: 'list' | 'splitView') {
			switch (type) {
				case 'list':
					return `${this._entertainmentPrefix()}${(new ApiCollection(this as never)).path.slice(0, -1)}`;
				default:
					return `${this._entertainmentPrefix()}${(new ApiCollection(this as never)).path.slice(0, -1)}/split`;
			}
		}
	};

	return c;
}
