import EntertainmentProvider    from "@/components/EntertainmentProvider";
import { ContextValue }         from "@/components/EntertainmentProvider";
import { EntertainmentContext } from "@/components/EntertainmentProvider";
import ShopEntertainmentModel   from "@/models/services/shop/EntertainmentModel";
import appStore                 from "@/stores/appStore";
import { navigate }             from "@widesk/hooks/useNavigate";
import { FC }                   from "react";
import { useContext }           from "react";
import { useEffect }            from "react";
import { useParams }            from "react-router-dom";

const useParamsToEntertainment = () => {
	const { entertainmentId: id } = useParams();
	
	useEffect(() => {
		appStore.changeEntertainment(id);
	}, [id]);
};

const navigateWithEntertainment = (to: string) => {
	const entertainmentId = appStore.entertainment instanceof ShopEntertainmentModel ? appStore.entertainment.id : 'all';
	navigate(`/entertainments/${entertainmentId}${to}`);
};

const useEntertainment = (): ContextValue => {
	return useContext(EntertainmentContext);
};

export { useParamsToEntertainment, navigateWithEntertainment };

export const withEntertainment = (WrappedComponent: FC) => (props: Record<string, unknown>) => {
	return (
		<EntertainmentProvider>
			<WrappedComponent {...props} />
		</EntertainmentProvider>	
	);
};

export default useEntertainment;
