export default [
	{
		path: '/',
		lazy: async () => {
			const Module = await import('../pages/Home/HomePage');
			return { Component: Module.default };
		},
	},
	{
		path: '/entertainments/:entertainmentId/home',
		lazy: async () => {
			const Module = await import('../pages/Home/HomePage');
			return { Component: Module.default };
		},
	},
];
