import WideskNavigation                                    from '@widesk/components/Navigation';
import SettingsNavigation                                  from './SettingsNavigation';
import navigation                                          from '@/navigation';
import { navigationAdmin }                                 from '@/navigation';
import { navigationSettings }                              from '@/navigation';
import View                                                from '@widesk/components/View';
import SiderSelectEntertainment                            from './SiderSelectEntertainment';
import { SetStateAction }                                  from 'react';
import { Dispatch }                                        from 'react';
import Button                                              from '@widesk/components/Button';
import { LeftOutlined }                                    from '@ant-design/icons';
import { RightOutlined }                                   from '@ant-design/icons';
import Title                                               from '@widesk/components/Title';
import { useParamsToEntertainment }                        from '@/hooks/useEntertainment';
import ShopEntertainmentModel                              from '@/models/services/shop/EntertainmentModel';
import { observer }                                        from 'mobx-react';
import appStore                                            from '@/stores/appStore';

type LayoutSiderProps = {
	collapsedState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const LayoutSider = observer(function (props: LayoutSiderProps) {
	const [collapsed, setCollapsed] = props.collapsedState;

	useParamsToEntertainment();
	const { entertainment } = appStore;

	return (
		<>
			<View flex style={{ overflow: 'auto' }} className="main-menu">

				<View flex row style={{ overflowY: 'auto', overflowX: 'hidden' }}>
					<div className="main-menu-sidebar">
						<SiderSelectEntertainment />

						<div className="admin-settings">
							<SettingsNavigation
								item={navigationSettings}
							/>
							<SettingsNavigation
								item={navigationAdmin}
							/>
						</div>
					</div>

					{entertainment && (
						<View flex>	
							<View className="main-menu__header" row>
								<Title className="main-menu__title" size={14}>
									{
										entertainment instanceof ShopEntertainmentModel 
										? entertainment.label 
										: 'Toutes les campagnes'
									}
								</Title>
								<Button type="link" onClick={() => setCollapsed(!collapsed)}>{ collapsed ? <RightOutlined /> : <LeftOutlined />}</Button>
							</View>
							{entertainment && <WideskNavigation key={entertainment === 'all' ? entertainment : entertainment.id} items={navigation(entertainment instanceof ShopEntertainmentModel ? entertainment.id : entertainment)} />}
						</View>
					)}
				</View>
			</View>
		</>
	);
});

export default LayoutSider;
