import theme from 'antd/lib/theme';

const { useToken } = theme;

export default function useTheme() {
	const { token } = useToken();

	return {
		borderRadius: token.borderRadius,
		colorIcon: token.colorIcon,
		colorSuccess: token.colorSuccess,
		colorError: token.colorError,
		colorWarning: token.colorWarning,
		colorLink: token.colorLink,
		colorLinkActive: token.colorLinkActive,
		colorBgContainer: token.colorBgContainer,
		colorBgLayout: token.colorBgLayout,
		colorTextLabel: token.colorTextLabel,
		colorBorder: token.colorBorder,
		colorBorderSecondary: token.colorBorderSecondary,
		colorTextDescription: token.colorTextDescription,
		colorPrimary: token.colorPrimary,
		colorPrimaryBgHover: token.colorPrimaryBgHover,
		colorTextBase: token.colorTextBase,
		controlItemBgHover: token.controlItemBgHover, // Couleur de fond dans les listes au hover
		marginXL: token.marginXL,
		marginXXL: token.marginXXL,
		marginLG: token.marginLG,
		marginMD: token.marginMD,
		marginSM: token.marginSM,
		marginXS: token.marginXS,
		marginXXS: token.marginXXS,
		sizeSM: token.sizeSM,
		fontSizeHeading1: token.fontSizeHeading1,
		fontSizeHeading2: token.fontSizeHeading2,
		fontSizeHeading3: token.fontSizeHeading3,
		fontSizeHeading4: token.fontSizeHeading4,
		fontSizeHeading5: token.fontSizeHeading5,
		Pagination: token.Pagination,
	};
}
