import CartStatusModel       from '@models/shop/CartStatusModel';
import ClientModel           from '@models/shop/ClientModel';
import CurrencyModel         from '@models/shop/CurrencyModel';
import doc                   from '@widesk/decorators/doc';
import model                 from '@widesk/decorators/model';
import ShopServiceModel      from '@/models/ShopServiceModel';
import TimestampAble         from '@widesk/models/traits/TimestampAble';
import Blamable              from '@/models/traits/Blamable';
import WithEntertainmentPath from '@/models/mixins/withEntetainmentPath';

@model.urnResource('cart')
@doc.path('/carts/{?id}')
export default class CartModel extends Blamable(TimestampAble(WithEntertainmentPath(ShopServiceModel))) {
	private declare _tsFilters: {
		'cartItems.productPricingClientGroup.productPricing.product.entertainment': id;
		'cartItems.productPricingClientGroup.productPricing.product.entertainment.entertainmentUrn': Urn;
		'cartStatus': id;
		'cartStatus.reference': Shop_CartStatusReference;
		'client': id;
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'expiresAt[after]': string | Date | Dayjs;
		'expiresAt[before]': string | Date | Dayjs;
		'expiresAt[strictly_after]': string | Date | Dayjs;
		'expiresAt[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'expiresAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.number declare amountTaxIncl: number;
	@doc.model(CartStatusModel) declare cartStatus: CartStatusModel;
	@doc.model(ClientModel) declare client: ClientModel;
	@doc.model(CurrencyModel) declare currency: CurrencyModel;
	@doc.date declare expiresAt: Dayjs;
}