import React     from 'react';
import AntdImage from 'antd/lib/image';

export type ImgProps = {
	src: string;
	preview?: boolean;
	style?: React.CSSProperties;
	width?: string | number;
}

export default function Img(props: ImgProps) {
	return (
		<AntdImage
			src={props.src}
			preview={props.preview}
			style={props.style}
			width={props.width}
		/>
	);
}
